import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { INavData } from '@coreui/angular';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})

export class DefaultLayoutComponent implements OnInit {

  public sidebarMinimized = false;
  public username = '';
  public navItems: INavData[] = [];
  constructor(private authService: AuthService, private router: Router) {

    this.username = this.authService.getUserName();

    const video = {
      name: 'Comment Utiliser Gaddiel',
      url: '/videotuto',
      icon: ''
    };
    this.navItems.push(video);

    this.renderAdministrations();
    this.renderConfigurations();
    this.renderVente();
    this.renderAchat();
    this.renderStock();
    this.renderCaisse();
    this.renderCompte();
    this.renderEtat();


  }

  ngOnInit() {

    // this.navItems =  this.sidebar.navItems;
  }


  renderAdministrations() {

    if (this.authService.roleMatch(['Administrateur', 'SuperAdmin'])) {

    const dashboard = {
      name: 'Tableau de Bord',
      url: '/dashboard',
      icon: ''
    };
    this.navItems.push(dashboard);

    const dashboardresto = {
      name: 'Tableau de Bord Resto',
      url: '/dashboardresto',
      icon: ''
    };
    this.navItems.push(dashboardresto);


      const extra = {
        title: true,
        name: 'APPLICATIONS'
      };
      this.navItems.push(extra);

      const titre = {
        name: 'ADMINISTRATION',
        url: '/administration',
        icon: '',
        children: []
      };

      if (this.authService.roleMatch(['SuperAdmin'])) {
        titre.children.push({
          name: 'Entreprises',
          url: '/administration/ListeEntreprises',
          icon: ''
        });
      }

      if (this.authService.roleMatch(['Administrateur', 'SuperAdmin'])) {
        titre.children.push({
          name: 'Utilisateurs',
          url: '/administration/ListeUtilisateurs',
          icon: ''
        });
      }

      this.navItems.push(titre);
    }

  }

  renderConfigurations() {

    if (this.authService.roleMatch(['Administrateur', 'SuperAdmin', 'Articles', 'Stock'])) {
      const titre = {
        name: 'CONFIGURATION',
        url: '/configuration',
        icon: '',
        children: [
          {
            name: 'Articles',
            url: '/configuration/ListeArticles',
            icon: ''
          },
          {
            name: 'Categories',
            url: '/configuration/ListeCategories',
            icon: ''
          }
        ]
      };
      this.navItems.push(titre);
    }


  }

  renderVente() {

    if (this.authService.roleMatch(['Administrateur', 'SuperAdmin', 'Vente', 'Factures'])) {
      const extra =   {
        title: true,
        name: 'VENTES'
      };
      this.navItems.push(extra);

      const titre = {
        name: 'VENTES',
        url: '/vente',
        icon: '',
        children: [{
          name: 'Liste des Clients',
          url: '/configuration/ListeClients',
          icon: ''
        },
        {
          name: 'Factures',
          url: '/vente/FactureClient',
          icon: ''
        },
        {
          name: 'Liste des Factures',
          url: '/vente/ListeFactures',
          icon: ''
        },
        {
          name: 'Devis',
          url: '/vente/Devis',
          icon: ''
        },
        {
          name: 'Liste des Devis',
          url: '/vente/ListeDevis',
          icon: ''
        },
          {
            name: 'Avoire Client',
            url: '/vente/Avoire',
            icon: ''
          },
          {
            name: 'Liste des Avoires',
            url: '/vente/ListeAvoire',
            icon: ''
          },
        {
          name: 'Retour Client',
          url: '/vente/BonRetour',
          icon: ''
        },
        {
          name: 'Liste des Retour',
          url: '/vente/ListeBonRetour',
          icon: ''
        },
        {
          name: 'Bon de Livraison',
          url: '/vente/BonLivraison',
          icon: ''
        },
        {
          name: 'Liste des BL',
          url: '/vente/ListeBonLivraison',
          icon: ''
        }]
      };

      this.navItems.push(titre);
    }


  }

  renderAchat() {

    if (this.authService.roleMatch(['Administrateur', 'SuperAdmin', 'Achat', , 'Factures Fournisseur'])) {
      const extra = {
        title: true,
        name: 'ACHATS'
      };
      this.navItems.push(extra);

      const titre = {
        name: 'ACHATS',
        url: '/achat',
        icon: '',
        children: [{
          name: 'Liste des Fournisseurs',
          url: '/configuration/ListeFournisseurs',
          icon: ''
        },
        {
          name: 'Factures',
          url: '/achat/FactureFournisseur',
          icon: ''
        },
        {
          name: 'Liste des Factures',
          url: '/achat/ListeFactures',
          icon: ''
        },
        {
          name: 'Retour Fournisseur',
          url: '/achat/BonRetour',
          icon: ''
        },
        {
          name: 'Liste des Retour',
          url: '/achat/ListeBonRetour',
          icon: ''
        },
        {
          name: 'Bon de Livraison',
          url: '/achat/BonLivraison',
          icon: ''
        },
        {
          name: 'Liste des BL',
          url: '/achat/ListeBonLivraison',
          icon: ''
        }]
      };


      this.navItems.push(titre);
    }


  }

  renderStock() {

    if (this.authService.roleMatch(['Administrateur', 'SuperAdmin', 'Stock'])) {
      const extra = {
        title: true,
        name: 'STOCK'
      };
      this.navItems.push(extra);

      const titre = {
        name: 'GESTION DE STOCK',
        url: '/stock',
        icon: '',
        children: [
          {
            name: 'Etat du Stock',
            url: '/stock/EtatStock',
            icon: ''
          },
          {
            name: 'Liste des Ajustements',
            url: '/stock/ListeAjustements',
            icon: ''
          },
          {
            name: 'Mouvement de Stock',
            url: '/stock/MouvementStock',
            icon: ''
          }
        ]
      };

      this.navItems.push(titre);
    }


  }

  renderCaisse() {

    if (this.authService.roleMatch(['Administrateur', 'SuperAdmin', 'Caisse'])) {
      const extra = {
        title: true,
        name: 'CAISSE'
      };
      this.navItems.push(extra);

      const titre = {
        name: 'GESTION DE CAISSE',
        url: '/caisse',
        icon: '',
        children: [
          {
            name: 'Operation de Caisse',
            url: '/caisse/operationsCaisse',
            icon: ''
          },
          {
            name: 'Liste des Comptes',
            url: '/caisse/ListeComptes',
            icon: ''
          },
          {
            name: 'Liste des Rubriques',
            url: '/caisse/ListeRubriques',
            icon: ''
          },
          {
            name: 'Journal de Caisse',
            url: '/caisse/JournalCaisse',
            icon: ''
          }
        ]
      };

      this.navItems.push(titre);
    }


  }

  renderCompte() {

    if (this.authService.roleMatch(['Administrateur', 'SuperAdmin', 'Compte'])) {
      const extra = {
        title: true,
        name: 'COMPTES'
      };
      this.navItems.push(extra);

      const titre = {
        name: 'GESTION COMPTE',
        url: '/compte',
        icon: '',
        children: [
          {
            name: 'Credit Clients',
            url: '/compte/ListeComptesClients',
            icon: ''
          },
          {
            name: 'Credit Fournisseurs',
            url: '/compte/ListeComptesFournisseurs',
            icon: ''
          },
          {
            name: 'Solde des Comptes',
            url: '/compte/ListeComptes',
            icon: ''
          },
          {
            name: 'Balance des Comptes',
            url: '/compte/Balance',
            icon: ''
          }
        ]
      };

    this.navItems.push(titre);
    }


  }

  renderEtat() {

    if (this.authService.roleMatch(['Administrateur', 'SuperAdmin'])) {
      const extra = {
        title: true,
        name: 'ETATS'
      };
      this.navItems.push(extra);

      const titre = {
        name: 'ETATS',
        url: '/rapport',
        icon: '',
        children: [
          {
            name: 'Statistique des Ventes',
            url: '/rapport/ArticleVendu',
            icon: ''
          },
          {
            name: 'Liste des Ventes',
            url: '/rapport/ListedesFactures',
            icon: ''
          },
          {
            name: 'Recette Detaille',
            url: '/rapport/RecetteDetaille',
            icon: ''
          },
          {
            name: 'Liste des Depenses',
            url: '/rapport/ListedesDepenses',
            icon: ''
          },
          {
            name: 'Liste des Entrees de Stock',
            url: '/rapport/ListeEntreeStock',
            icon: ''
          },
          {
            name: 'Mouvement de Stock',
            url: '/rapport/MouvementdeStock',
            icon: ''
          },
          {
            name: 'Etat du Stock',
            url: '/rapport/listearticle',
            icon: ''
          },
          {
            name: 'Article en Rupture',
            url: '/rapport/listearticlerupture',
            icon: ''
          },
          {
            name: 'Balance de Compte',
            url: '/rapport/Balance',
            icon: ''
          },
          {
            name: 'Client Par Fournisseur',
            url: '/rapport/PointClient',
            icon: ''
          },
          {
            name: 'Etat du Stock Par Fournisseur',
            url: '/rapport/EtatStockFournisseur',
            icon: ''
          },
          {
            name: 'Balance Par Fournisseur',
            url: '/rapport/BalanceParFournisseur',
            icon: ''
          },
          {
            name: 'Balance Client',
            url: '/rapport/BalanceClient',
            icon: ''
          },
          {
            name: 'Balance Fournisseur',
            url: '/rapport/BalanceFournisseur',
            icon: ''
          }
        ]
      };


      this.navItems.push(titre);
    }

  }


  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    console.log('logout');
    this.authService.logout();
    this.router.navigate(['login']);
  }

}
