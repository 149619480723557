import { Component, OnInit } from '@angular/core';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { AccueilService } from '../../services/AccueilService';
import { Stats } from '../../models/Stats';
import { State } from '@progress/kendo-data-query';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { AccueilDService } from '../../services/AccueilDService';
import { AccueilPService } from '../../services/AccueilPService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboardresto',
  templateUrl: './dashboardresto.component.html',
  styleUrls: ['./dashboardresto.component.css']
})
export class DashboardrestoComponent implements OnInit {
  private apiName: string = 'AccueilResto';
  public view: AccueilService;
  public viewd: AccueilDService;
  public viewp: AccueilPService;
  public stat : Stats = new Stats();  
  public debut: Date = new Date();  
  public fin: Date = new Date();
  public state: State = {
    skip: 0,
    take: 20,
    filter: { filters: [], logic: 'or' },
    group: [],
    sort: []
  };

  public stateD: State = {
    skip: 0,
    take: 20,
    filter: { filters: [], logic: 'or' },
    group: [],
    sort: []
  };

  public stateP: State = {
    skip: 0,
    take: 20,
    filter: { filters: [], logic: 'or' },
    group: [],
    sort: []
  };
  //public allData: Array<any> = [];
  constructor(private service: AccueilService, private serviceD: AccueilDService, private serviceP: AccueilPService, private router: Router) {
    this.allData = this.allData.bind(this);
    this.allDataDepense = this.allDataDepense.bind(this);
    this.allDataPaiement = this.allDataPaiement.bind(this);

  }

  ngOnInit(): void {
 
    this.load();
  }

  public onStateChange(state: DataStateChangeEvent): void {
    this.service.apiName(this.apiName);
    this.service.state = state;
    this.state = state;
    this.service.read(this.debut, this.fin);
  }

  public onStateDChange(state: DataStateChangeEvent): void {
    this.serviceD.apiName(this.apiName);
    this.serviceD.state = state;
    this.stateD = state;
    this.serviceD.read(this.debut, this.fin);
  }
  
  public onStatePChange(state: DataStateChangeEvent): void {
    this.serviceP.apiName(this.apiName);
    this.serviceP.state = state;
    this.stateP = state;
    this.serviceP.read(this.debut, this.fin);
  }

  public allData = (): Observable<any> => {
    this.service.apiName(this.apiName);
    return this.service.queryAll(this.debut, this.fin, this.state);
  }

  public allDataDepense = (): Observable<any> => {
    this.serviceD.apiName(this.apiName);
    return this.serviceD.queryAll(this.debut, this.fin, this.state);
  }
  
  public allDataPaiement = (): Observable<any> => {
    this.serviceP.apiName(this.apiName);
    return this.serviceP.queryAll(this.debut, this.fin, this.state);
  }
  private load() {
    this.service.apiName(this.apiName);
    this.serviceD.apiName(this.apiName);
    this.serviceP.apiName(this.apiName);

    this.stat.totalVendu = 0;
    this.stat.credit = 0;
    this.stat.depense = 0;
    this.stat.totalCaisse = 0;
    
    //this.debut = new Date(2000,5,1,0,0,0);

    this.service.filter(this.debut, this.fin).subscribe(cats => {
      this.stat = cats;
    });

    this.view = this.service;
    this.service.read(this.debut, this.fin);

    this.viewd = this.serviceD;
    this.serviceD.read(this.debut, this.fin);
    
    this.viewp = this.serviceP;
    this.serviceP.read(this.debut, this.fin);

  }

  public onChange(value: Date): void {

    this.load();
  }

}
